import React from "react"

import { Container, Row, Col, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ContactUs from "components/ContactUs/ContactUs"
import style from "./style.module.scss"
import { FormattedMessage } from "react-intl"
import bg from "./header.jpg"
import bg2 from "./header2.jpg"
import Image from "components/Image/index"
// import MyDropzone from "components/MyDropzone/MyDropzone"

export default function({
  id,
  title,
  subtitle,
  description,
  productImg,
  productIcon,
  productFeatures,
  technicalTable,
  children,
  images,
  type,
}) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subtitle} />
      </Helmet>
      <div
        xs="12"
        style={{
          backgroundImage: `url(${type === "wood" ? bg2 : bg})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          color: "#fff",
          padding: "20px 0px",
          textShadow: "1px 1px 3px #000",
        }}
      >
        <Container className={style.top}>
          <Row>
            <Col xs="12" md="8" lg="6">
              <h1>{title}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={`${style.container} ${style.top}`}>
        <Row>
          <Col xs="12" md="6" lg="4">
            {productImg.split(".").pop() === "mp4" ? (
              <video
                autoPlay
                muted
                loop
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#000",
                }}
              >
                <source src={productImg} type="video/mp4" />
              </video>
            ) : (
              <Image filename={productImg} alt={`${id}`} />
            )}
          </Col>
          <Col xs="12" md="6" lg="5">
            <h2 className={style.muted}>{subtitle}</h2>
            {description}
          </Col>
          <Col xs="12" md="12" lg="3">
            <div className={style.contact}>
              <img
                src={productIcon}
                alt={`Logo ${id}`}
                className={style.icon}
              />
              <h4>
                <FormattedMessage id="productOverview" />
              </h4>
              <ul>
                {productFeatures &&
                  productFeatures.map(e => <li key={e}>{e}</li>)}
              </ul>
              <ContactUs />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="light-grey-background">
        <Container className={style.container}>
          <Row>
            <Col md="7">
              <h2>
                <FormattedMessage id="productDetails" />
              </h2>
              {children}
              {images && images.length > 0 && (
                <Row>
                  {images.map(entry => (
                    <Col xs="6" md="4">
                      {entry.split(".").pop() === "mp4" ? (
                        <video
                          autoPlay
                          muted
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#000",
                          }}
                        >
                          <source src={entry} type="video/mp4" />
                        </video>
                      ) : (
                        <Image
                          filename={entry}
                          alt="Gallery item for product"
                        />
                      )}
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
            {technicalTable ? (
              <Col md="5" className="white-background">
                <h2>
                  <FormattedMessage id="productTechnical" />
                </h2>
                {technicalTable}
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
      <Container className={style.container}>
        <Row className="pb-5">
          <Col xs="12" md="4" className="d-flex">
            <Button
              className={style.cta}
              onClick={() => {
                window.$crisp && window.$crisp.push(["do", "chat:open"])
              }}
            >
              <FormattedMessage id="productContactCTA" /> {id}
            </Button>
          </Col>
          <Col xs="12" md="8" className="py-5">
            <ContactUs large />
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row>
          <Col xs="12">
            <MyDropzone product={id} />
          </Col>
        </Row>
      </Container> */}
    </React.Fragment>
  )
}
