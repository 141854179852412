import React from "react"
import style from "./style.module.scss"
import salesImg from "./philipp.jpg"
import { FormattedMessage } from "react-intl"

export default function(props) {
  const telNr = "+4318901287"
  const telNrShow = "+43 676 6918980"
  const email = "office@moonvision.io"
  return (
    <div className={style.contact}>
      <h4>
        <FormattedMessage id="contactTitle" />
      </h4>
      <img src={salesImg} alt="Portraitfoto Philipp Posch" />
      <strong>Philipp Posch</strong>
      <br />
      {!props.large && (
        <React.Fragment>
          <a
            href={`tel:${telNr}`}
            className="btn btn-sm btn-primary m-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="contactCall" />
          </a>
          <a
            href={`mailto:${email}`}
            className="btn btn-sm btn-primary m-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="contactEmail" />
          </a>
        </React.Fragment>
      )}
      {props.large && (
        <React.Fragment>
          tel:{" "}
          <a
            href={`tel:${telNr}`}
            className="m-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {telNrShow}
          </a>
          <br />
          mail:{" "}
          <a
            href={`mailto:${email}`}
            className="m-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {email}
          </a>
        </React.Fragment>
      )}
    </div>
  )
}
