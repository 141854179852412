import React from "react"

import Layout from "components/Layout/de"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./WeldScanner.svg"
import { Table } from "react-bootstrap"
import { MdDone, MdCancel } from "react-icons/md"

export default function(props) {
  const description = <React.Fragment><p>Reduzieren Sie die Zeit für die Vermessung von Schweißnähten (zB nach DIN EN 17637) auf unter eine Sekunde! Die MoonVision Software unterscheidet automatisch und präzise die Schweißnaht vom Werkstück und misst visuell, ob das Ergebnis des Fügeverfahrens ihren Qualitätsanforderungen entspricht. Dabei werden unterschiedliche Nahttypen erkannt und berücksichtigt.</p>
  <h3>Highlights</h3>
  <ul>
    <li>Automatisierte Qualitätsprüfung von Schweißnähten</li>
    <li>Von gleichbleibender Qualität und verkürzter Durchlaufzeit profitieren</li>
    <li>Erkennung und Messung mittels Kamera & Software</li>
    <li>Höchste Genauigkeit & Komfort durch Künstliche Intelligenz</li>
    <li>Auswertungen an Folgesysteme oder visuelle Darstellung</li>
  </ul></React.Fragment>

  return (
    <Layout location={props.location}>
      <Product
        id="WeldScanner"
        title="Qualitätsprüfung von Schweißnähten in Echtzeit"
        subtitle="Effiziente Software für die Schweißnahtanalyse - einfach zu
              bedienen und in Echtzeit"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automatisierte Schweißnahtprüfung",
          "Gleichbleibend hohe Qualität",
          "Verkürzte Durchlaufzeit",
          "Jetzt Informieren",
        ]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Überprüfung von</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Form</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Ansätze</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Gleichmäßigkeit/Erscheinungsform</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Übergänge</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Defekterkennung</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Risse</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Einbrandkerbe</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Abweichungen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Erhöhungen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Einkerbungen</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Einschlüsse</td>
                <td>
                  <MdCancel />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Anwendungsgebiete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alle Materialien überprüfbar</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Vor Ort Installation möglich</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Cloud Anwendung möglich</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            Der MoonVision WeldScanner wurde speziell für die Qualitätsprüfung nach dem Schweißvorgang entwickelt. Dabei werden neueste Technologien wie Machine Learning eingesetzt, um zwischen verschiedenen Schweißnaht-Arten und Beschaffenheit zu unterscheiden und diese möglichst exakt zu erfassen. Im Anschluss werden Messungen durchgeführt. Prüfungen stellen sicher, dass Ihre Schweißnähte geltenden Standards entsprechen oder Sie definieren eigene Prüfregeln.
          </p>
          <p>
            Durch die eingesetzte Künstliche Intelligenz werden bestehende Systeme im Hinblick auf die Prüfgenauigkeit in den Schatten gestellt – der Bewertungsprozess wird vollautomatisiert und Sie sparen wertvolle Zeit.
          </p>
          <p>
            MoonVision Software unterstützt sie unter anderem bei der
            Evaluierung nach:
          </p>
          <ul>
            <li>Din EN 17637 </li>
            <li>DIN EN 5817 (mit Zuordnung zu Bewertungsgruppen)</li>
            <li>DIN EN 13018 </li>
            <li>DIN EN 10042</li>
          </ul>
          <p>
            Der MoonVision WeldScanner kann an unterschiedlichen Produktionsschritten von der Wareneingangskontrolle bis hin zur Warenausgangskontrolle eingesetzt werden. Durch das autarke Design kann die Software in einen laufenden Fertigungsprozess bzw. vorhandene ERP-Systeme integriert werden.
          </p>
          <p>Nach Ablauf der Kontrolle können die Ergebnisse ausserdem:</p>
          <ul>
            <li>
              Visuell dargestellt werden (Auf Monitor/Alarme/Ablage von jpg
              Dateien){" "}
            </li>
            <li>Tabellarisch zusammengefasst werden </li>
            <li>
              Per API an Managementinformationssysteme weitergegeben werden{" "}
            </li>
            <li>Per APIs zur Prozesssteuerung eingesetzt werden</li>
          </ul>
          <p>Durch das MoonVision Konzept</p>
          <ul>
            <li>
              können individuelle Anpassungen selbstständig durchgeführt werden
            </li>
            <li>
              bleibt der Zugang und Kontrolle über die Erkennungsparameter bei
              ihnen
            </li>
          </ul>
          <h3>Überall Einsetzbar</h3>
          <p>
            Der MoonVision WeldScanner kann alle bestehenden Kamerasysteme als Datenquellen nutzen – die den Genicam Standard erfüllen. Gerne beraten wir Sie auch bei der Wahl des richtigen Systems.
            Die Überprüfung kann in Echtzeit über unsere Online-Plattform erfolgen. Alternativ dazu können wir das System auch vor Ort installieren. In beiden Fällen erhalten Sie Zugriff zur MoonVision Toolbox und können damit jederzeit Anpassungen zB zur Parameteradaptierung und Personalisierung durchführen.
          </p>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Softwaredesigns ist die Optimierung und Implementierung des MoonVision WeldScanners mit geringem Aufwand vor Ort bei Ihnen realisierbar. Bereits nach der Überprüfung weniger Bild/Video Daten herrscht Gewissheit über die Qualität, weitere Bilddaten können auch zu einem späteren Zeitpunkt ergänzt werden. Alle Daten werden zentral gesammelt, gespeichert und sind jederzeit für Sie abrufbar.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
